/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { first, isArray, isEmpty, isNil, last, orderBy, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GroupBase,
  MenuPlacement,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { useEffectOnce, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { CollectionService } from 'services/collection.service';
import { addNewCollection } from 'slices/collection.slice';
import { userSelector } from 'slices/payment.slice';
import { CollectionType, Dropdown } from 'utils/enum';
import { CollectionDropdownOption } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { Hint } from '../Hint';
import { CollectionMultipleItem } from './CollectionMultipleItem';
import { CollectionSingleItem } from './CollectionSingleItem';
import { appendOrDelete } from 'utils/generic.util';
import { AxiosError } from 'axios';
import { selectDropdownStyles } from 'utils/utils';

type CommonSelect = {
  disabled?: boolean;
  tabIndex?: number;
  defaultValue?: string;
  defaultLabel?: string;
  menuPlacement?: MenuPlacement;
  isRssSubscription?: boolean;
  canCreate?: boolean;
  hiddenSelectedOptions?: boolean;
  placeholder?: string;
};

type SingleSelect = {
  isMultiSelect: false;
  onChange: (collectionId: string) => void;
};

type MultiSelect = {
  isMultiSelect: true;
  selectedCollections: string[];
  onMultiSelectChange: (collectionIdList: string[]) => void;
};

type Props = CommonSelect & (SingleSelect | MultiSelect);

export const CollectionDropdown = (props: Props) => {
  const { isMultiSelect, hiddenSelectedOptions } = props;

  const collection = useSelector((state: RootState) => state.collection);

  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  const [displayValue, setDisplayValue] = useState(props.defaultValue);
  const [collectionsCallback, setCollectionsCallback] = useState<string[]>(
    isMultiSelect ? props?.selectedCollections : [],
  );
  const [hovering, toggleHovering] = useToggle(false);

  const checkVisibleById = (id: string): boolean => {
    if (!isMultiSelect) return true;

    if (!hiddenSelectedOptions) return true;

    const isSelected = props.selectedCollections.includes(id);

    return !isSelected; // Hide selected
  };

  const collections = collection.items
    .filter((item) => checkVisibleById(item.id))
    .map((item) => ({
      label: item.name,
      value: item.id,
      type: item.type,
    }));

  const hoveringText = useMemo<string>(() => {
    const findCollectionNameFn = (collectionId: string) =>
      collections.find((option) => option.value === collectionId)?.label;

    return collectionsCallback
      .filter(checkVisibleById)
      .map(findCollectionNameFn)
      .join(', ');
  }, [collectionsCallback, isMultiSelect && props.selectedCollections]);

  useEffect(() => {
    setDisplayValue(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    if (!isMultiSelect) return;

    if (!hiddenSelectedOptions) return;

    // Reset selected items after updating selectionCollections by tag
    setCollectionsCallback([]);
    setDisplayValue(props.placeholder || '');
  }, [isMultiSelect ? props.selectedCollections.length : undefined]);

  useEffectOnce(() => {
    if (isMultiSelect) {
      const newDefaultValue = getDisplayValue(
        !hiddenSelectedOptions ? props.selectedCollections : [],
        collections,
      );
      setDisplayValue(newDefaultValue);
    }
  });

  const handleChangeOption = (e: SingleValue<CollectionDropdownOption>) => {
    if (isNil(e?.value)) return;
    let collectionId = e?.value;
    setDisplayValue(collectionId);

    if (!isMultiSelect) {
      props.onChange(collectionId as string);
    }
  };

  const handleAddNew = async (value: string) => {
    // add new collection
    if (value.length > 128) {
      customToast.error(
        <div tw="whitespace-normal word-break[keep-all]! word-wrap[break-word]!">
          Collection name must be lesser than 128 characters.
        </div>,
      );
      return false;
    }

    try {
      const addCollectionAsync = CollectionService.createCollection({
        collection_name: value,
      });

      customToast.loading('Creating collection...');
      const { data: collectionId } = await addCollectionAsync;

      customToast.success(
        <div>
          <b>{value}</b> created successfully.
        </div>,
      );

      dispatch(
        addNewCollection({
          id: collectionId,
          type: CollectionType.STANDARD,
          name: value,
        }),
      );

      if (isMultiSelect) {
        const newCollectionsCallback: string[] = uniq([
          ...collectionsCallback,
          collectionId,
        ]);

        const newDefaultValue = getDisplayValue(newCollectionsCallback, [
          ...collections,
          {
            label: value,
            value: collectionId,
            type: CollectionType.STANDARD,
          },
        ]);
        setDisplayValue(newDefaultValue);

        setCollectionsCallback(newCollectionsCallback);

        // MULTI SELECT
        if (isMultiSelect) {
          props.onMultiSelectChange(newCollectionsCallback);
        }

        return;
      }

      setDisplayValue(collectionId);

      // SINGLE SELECT
      if (typeof props?.onChange === 'function') {
        props.onChange(collectionId as string);
      }

      // Replace with real collection id
    } catch (err: any) {
      // Rollback if create collection failed
      console.log('err', err);

      const errorMessage = (err as AxiosError)?.response?.data;

      errorMessage && customToast.error(errorMessage);
    }
  };

  const getDisplayValue = (
    selectedCollectionIds: string[],
    collectionOptions: CollectionDropdownOption[],
  ) => {
    if (isEmpty(selectedCollectionIds) || !isArray(selectedCollectionIds)) {
      return props?.placeholder ?? '';
    }

    if (selectedCollectionIds.length === 1) {
      const collectionName = collectionOptions.find(
        (option) => option?.value === first(selectedCollectionIds),
      )?.label;

      return collectionName;
    }

    return 'Multiple collections selected';
  };

  const handleMenuClose = () => {
    if (isMultiSelect) {
      props.onMultiSelectChange(collectionsCallback);

      toggleHovering(false);
    }
  };

  const handleClearSelected = () => {
    setCollectionsCallback([]);
    setDisplayValue(props.placeholder || '');

    if (isMultiSelect) {
      props.onMultiSelectChange([]);
    }
  };

  const handleSelectOption = (selectedOption: CollectionDropdownOption) => {
    const selectedList = appendOrDelete(
      collectionsCallback,
      selectedOption.value,
    );

    setCollectionsCallback(selectedList);

    const newDefaultValue = getDisplayValue(selectedList, collections);
    setDisplayValue(newDefaultValue);
  };

  const collectionsFiltered = orderBy(
    collections,
    [
      (obj) => {
        const index = collectionsCallback.indexOf(obj.value);

        return index === -1 ? Infinity : index; // Not found items to bottom
      },
    ],
    ['asc'],
  );

  const isOpenHint =
    isMultiSelect && collectionsCallback.length > 1 && hovering;

  return (
    <div
      onMouseOver={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      <Hint
        open={isOpenHint}
        text={hoveringText}
        enterDelay={200}
        leaveDelay={100}
      >
        <CustomSelectSearch
          options={[
            {
              label: props.defaultLabel || 'No collection',
              value: '',
              type: CollectionType.NONE,
            },
            ...collectionsFiltered,
          ]}
          formatOptionLabel={(option: CollectionDropdownOption) =>
            isMultiSelect ? (
              <CollectionMultipleItem
                option={option}
                optionList={collectionsCallback}
                onClearSelected={handleClearSelected}
                onSelectOption={handleSelectOption}
              />
            ) : (
              <CollectionSingleItem option={option} />
            )
          }
          onChange={handleChangeOption}
          handleAddNew={handleAddNew}
          disabled={props?.disabled}
          customStyles={renderStyle(collections, props?.isRssSubscription)}
          tabIndex={props?.tabIndex}
          defaultValue={displayValue || ''} // Equivalent to `No collection`
          mode={Dropdown.COLLECTION}
          menuPlacement={props?.menuPlacement}
          canCreate={props?.canCreate ?? true}
          onMenuClose={handleMenuClose}
          isMultiSelect={isMultiSelect}
          isLoading={user.isLoading}
        />
      </Hint>
    </div>
  );
};

const renderStyle = (
  collections: CollectionDropdownOption[],
  isRssSubscription?: boolean,
): StylesConfig<any, false, GroupBase<any>> => {
  const customSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
    ...selectDropdownStyles,
    input: (base, props) => {
      const { options, value } = props;
      const isNew = last(options)?.__isNew__;

      if (!isNew) {
        // existed search term
        const foundIndex = collections.findIndex(
          (i) => i.label.toLowerCase() === value?.toString().toLowerCase(),
        );
        if (foundIndex >= 0) {
          return {
            ...base,
            width: '19rem',
            position: 'relative',
            top: '-0.5rem',
            fontSize: '1.4rem',
            pointerEvents: 'none',
          };
        }

        return {
          ...base,
          width: '19rem',
          fontSize: '1.4rem',
          height: '3rem',
          paddingTop: '3px',
          position: 'relative',
          top: '-8px',
        };
      }

      return {
        ...base,
        width: '19rem',
        height: '3rem',
        paddingTop: '3px',
        position: 'relative',
        top: '-8px',
        fontSize: '1.4rem',
        pointerEvents: 'none',
      };
    },
  };
  if (isRssSubscription) {
    return {
      ...customSelectStyles,
      input: (base, props) => {
        const { options } = props;
        const isCreation = last(options)?.__isNew__;

        if (!isCreation) {
          return {
            ...base,
            position: 'relative',
            top: '-4.6rem',
            fontSize: '1.4rem',
          };
        }

        return {
          ...base,
          height: '3rem',
          paddingTop: '3px',
          position: 'relative',
          top: '-8px',
          fontSize: '1.4rem',
        };
      },
      placeholder: (base) => ({
        ...base,
        color: 'black',
        fontWeight: 'normal',
        fontSize: '14px',
        width: '15rem',
        height: '4rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        lineHeight: '36px',
        position: 'relative',
        top: '1px',
      }),
      container: (base) => ({
        ...base,
        height: '4rem',
        width: '20rem',
        minWidth: '15rem',
        // zIndex: 1000, // Prevent overlapping the modal Integrations Tab
      }),
      menu: (base) => ({
        ...base,
        marginBottom: '4px',
        marginTop: '1px',
        width: '21rem',
      }),
      valueContainer: (base) => ({
        ...base,
        padding: 0,
        paddingLeft: '0.6rem',
        display: 'flex',
        height: 'inherit',
      }),
    };
  }
  return { ...customSelectStyles };
};
